

const enum AuthActionType {
  Default = "",
  RequestSignIn = "REQUEST_SIGN_IN",
  SignInSuccess = "SIGN_IN_SUCCESS",
  SignInError = "SIGN_IN_ERROR",
  RequestSignOut = "REQUEST_SIGN_OUT",
  SignOutSuccess = "SIGN_OUT_SUCCESS",
  SignOutError = "SIGN_OUT_ERROR",
  SignInChallenge = "SIGN_IN_CHALLENGE",
  UpdateAuthState = "UPDATE_AUTH_STATE"
}

export default AuthActionType;