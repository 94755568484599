import { AuthStateProps, AuthReducerAction } from "./types";
import { AuthActionType } from "./constants"; 

const defaultState: AuthStateProps = {
    loading: false,
    authenticated: false,
    sessionToken: "",
};

export const initialState = defaultState;

export const AuthReducer = (state: AuthStateProps, action: AuthReducerAction): AuthStateProps => {
	switch (action.type) {
    case AuthActionType.Default:
      return {
        authenticated: state.authenticated,
        loading: false
      }
		case AuthActionType.RequestSignIn:
			return {
        authenticated: state.authenticated,
				loading: true,
			};
		case AuthActionType.SignInChallenge:
			return {
				...state,
				sessionToken: action.payload?.sessionToken || ""
			}
		case AuthActionType.SignInSuccess:
			return {
				authenticated: true,
				loading: false,
			};
		case AuthActionType.SignInError:
			return {
        authenticated: false,
				loading: false,
				errorMessage: "Invalid e-mail or password",
			};
		case AuthActionType.RequestSignOut:
			return {
				...state,
				loading: true,
			};
		case AuthActionType.SignOutSuccess:
			return defaultState;
		case AuthActionType.SignOutError:
			return {
				...state,
				loading: false,
				errorMessage: "Sign-out error",
			};
    case AuthActionType.UpdateAuthState:
      return {
        authenticated: true,
        loading: false,
        email: action.payload?.email || "",
        userId: action.payload?.userId || ""
      };
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	};
};

