import React from "react";
import { AuthProviderProps, AuthReducerAction, AuthStateProps } from "./types";
import { AuthActionType } from "./constants";
import { AuthReducer, initialState } from "./authReducer";

const AuthStateContext = React.createContext<AuthStateProps>({} as AuthStateProps);
const AuthDispatchContext = React.createContext<React.Dispatch<AuthReducerAction>>(
	{} as React.Dispatch<AuthReducerAction>
);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = React.useReducer(AuthReducer, initialState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

export const useAuthState = () => {
	const context = React.useContext(AuthStateContext);
	if (context === undefined) {
		throw new Error('useAuthState must be used within an AuthProvider');
	}

	return context;
};

export const useAuthDispatch = () => {
	const context = React.useContext(AuthDispatchContext);
	if (context === undefined) {
		throw new Error('useAuthDispatch must be used within an AuthProvider');
	}

	return context;
}