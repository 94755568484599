import React from "react";
import { Footer } from "@trussworks/react-uswds";
import "./App.css";

const AppFooter: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Footer size="slim" /*returnToTop={returnToTop}*/ primary={
      <div>
        <span>© {currentYear} Authentication App. All rights reserved.</span>
      </div>
    } secondary={<></>}>

    </Footer>
  );
};

export default AppFooter;