import { apiRequest } from "../../../utils/apiService";

export async function requestSignIn(email: string, password: string) {
  return await apiRequest("/auth/signin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email: email,
      password: password
    })
  });
}

export async function requestSignOut() {
  return await apiRequest("/auth/signout", { method: "POST" });
}

export async function changePassword(newPassword: string, sessionToken: string) {
  return await apiRequest("/auth/password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      newPassword: newPassword,
      sessionToken: sessionToken
    })
  });
}

