import React from "react";
import HomePage from "../../features/homePage/HomePage"



interface RouteProps {
  path: string,
  page: React.FC
}

export const ROUTES: Array<RouteProps> = [
  { path: "/", page: HomePage },
];
